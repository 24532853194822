<template>
  <div v-if="isLoading" id="preloader">
    <div class="spinner-border color-highlight" role="status"></div>
  </div>

  <div id="page">
    <!-- Header -->
    <Header v-if="userType !== 'E'" :shareBtn="true" :bookmarked="bookmarked" @bookmark="bookmark" />
    <Header v-else  />
    <!--End Header -->

    <!-- Footer -->
    <Footer logged />
    <!--End Footer -->

    <div class="page-title-clear"></div>
    <div class="page-content" v-if="showJobDetail">
      <!-- Company -->
      <div class="card card-style">
        <div class="content align-self-center text-center">
          <div class="card bg-transparent">
            <div class="align-self-center mt-3 text-center">
              <h4 class="mb-2">
                {{ jobData.title }}
              </h4>
              <img
                @error="imageUrlAlt"
                :src="logoUrl"
                class="card-img rounded-m preload-img img-fluid entered loaded"
                alt=""
                style="height: 84px"
              />
              <!-- style="height: 84px; object-fit: cover; max-width: " -->
              <div
                class="card-img-overlay p-0 d-flex align-items-end mx-auto"
                style="width: 84px"
                v-if="jobData.status_badge === 1"
              >
                <div
                  class="w-100 text-center opacity-90"
                  style="
                    background-color: #fb6e50 !important;
                    border-bottom-left-radius: 8px;
                    border-bottom-right-radius: 8px;
                  "
                >
                  <span class="fw-bold text-white" style="font-size: x-small"
                    >#HIRING</span
                  >
                </div>
              </div>
            </div>
          </div>
          <p class="mb-0 mt-2 font-800">
            <router-link
              :to="{
                name: 'CompanyJobList',
                params: { id: jobData.company.slug },
              }"
              >{{ jobData.company.business_name }}</router-link
            >
          </p>
        </div>

        <div class="content">
          <p class="mb-2">
            {{ jobData.company.long_about }}
          </p>
          <p class="mb-2">
            {{ jobData.company.city.name }},
            {{ jobData.company.province.name }},
            {{ jobData.company.country.name }}
          </p>
          <p class="mb-2">
            {{ jobData.company.website }}
          </p>
          <p class="mb-2">
            Position:
            <span v-if="jobData.level">{{ jobData.level.name }}</span> -
            {{ jobData.get_worktime_display }}
          </p>
          <p class="mb-2" v-if="jobData.hide_salary === false">
            Range Salary: {{ $filters.formatPrice(jobData.salary_start) }} -
            {{ $filters.formatPrice(jobData.salary_end) }}
          </p>
        </div>
      </div>
      <!-- End Company -->

      <!--Description -->
      <div class="card card-overflow card-style">
        <div class="content" :class="{ 'bluer-layer': hideInformation }">
          <div v-if="jobData.company">
            <p v-if="jobData.company.headline" class="mb-4">
              {{ jobData.company.headline }}
            </p>
          </div>

          <p class="mb-0">
            <strong class="color-theme">Penempatan Kerja</strong>
          </p>

          <p class="mb-4 keep-spaces">
            <span
              v-html="jobData.work_loc_city ? jobData.work_loc_city.name : ''"
            ></span
            >,
            <span
              v-html="
                jobData.work_loc_province ? jobData.work_loc_province.name : ''
              "
            ></span
            >,
            <span
              v-html="
                jobData.work_loc_country ? jobData.work_loc_country.name : ''
              "
            ></span>
          </p>

          <p class="mb-0">
            <strong class="color-theme">Deskripsi Pekerjaan</strong>
          </p>

          <p class="mb-4 keep-spaces">
            <span v-html="jobData.description"></span>
          </p>

          <p class="mb-0">
            <strong class="color-theme">Kualifikasi</strong>
          </p>
          <p class="mb-4 keep-spaces">
            <span v-html="jobData.requirement" class=""></span>
          </p>

          <p class="mb-0">
            <strong class="color-theme">Keahlian</strong>
          </p>
          <p class="mb-4 keep-spaces">
            <span v-html="jobData.skill_desc" class=""></span>
          </p>

          <p class="mb-0">
            <strong class="color-theme">Periode</strong>
          </p>
          <p class="mb-4">
            {{ getDate(jobData.created_at) }} - {{ jobData.expire_at }}
          </p>

          <div class="divider mt-3"></div>
          <a
            v-if="userType !== 'E'"
            @click="apply(jobData.id)"
            href="#"
            class="btn btn-full btn-m font-600 rounded-s shadow-l"
            :class="{
              'bg-red-dark': disableBtn,
              'gradient-highlight': !disableBtn,
            }"
            >{{ btnText }}</a
          >
        </div>
        <div v-if="hideInformation" id="text-information">
          <p class="boxed-text-l font-800 font-14 mt-5 rounded-m">
            Apply lowongan dan posting lowongan secara gratis.
          </p>
          <router-link
            :to="{ name: 'SignIn', query: { invite_code: inviteCode } }"
            class="close-menu btn button-s shadow-l rounded-m font-800 font-14 bg-highlight color-black"
            style="font-size: 16px !important"
            >Silahkan login untuk melihat info lengkap lowongan !
          </router-link>
          <p class="boxed-text-l font-12 mt-2 color-dark-dark">
            Jika belum memiliki akun, daftar
            <router-link
              :to="{
                name: 'CreateConfirm',
                query: { invite_code: inviteCode },
              }"
              >disini</router-link
            >
          </p>
        </div>
      </div>

      <a
        href="https://docs.google.com/forms/d/e/1FAIpQLScPsMhzdGxVPGYjxdj9bXPe05aT12NBF4jkjufOYGCWf-jbFA/viewform?embedded=true%22"
        class="card card-style mt-1 gradient-highlight"
      >
        <div class="card-body m-2">
          <div class="row align-items-center mb-0">
            <div class="col-8">
              <h6 class="text-white">
                Ajukan Untuk Teman Anda <br />
                Langsung Dari <span style="color: #8c8c8c">Pas</span>Job
              </h6>
            </div>
            <div class="col-4">
              <img
                src="/images/banner/undraw_working_remotely_jh40.png"
                class="img-fluid"
                alt="remote"
              />
            </div>
          </div>
        </div>
      </a>
    </div>

    <!-- userprofiole -->
    <div v-if="!showJobDetail" class="page-content pb-0">
      <div data-card-height="" class="card mb-0">
        <div class="content">
          <p class="text-center color-highlight font-600 font-16">
            Ayo lengkapi data berikut untuk Apply !
          </p>
          <!-- Form -->
          <div>
            <p v-if="errors" class="color-red-dark mb-0">
              {{ errors.errorMsg }}
            </p>

            <p class="font-18 font-500 mb-3 color-highlight">
              Personal Information
            </p>
            <div
              class="input-style has-borders no-icon validate-field mb-4 input-style-always-active"
              :class="{ 'input-style-active': dataAccount.gender }"
            >
              <label for="form5a">Jenis Kelamin</label>
              <select
                class="form-control"
                id="form5a"
                v-model="dataAccount.gender"
              >
                <option value="M">Laki - Laki</option>
                <option value="F">Perempuan</option>
              </select>
              <span v-if="dataAccount.gender"
                ><i class="fa fa-chevron-down"></i
              ></span>
              <i class="fa fa-check disabled valid color-green-dark"></i>
              <i class="fa fa-check disabled invalid color-red-dark"></i>
              <em
                v-if="!dataAccount.gender"
                :class="{
                  'color-red-dark font-800 req-val': errors.gender,
                }"
                >(required)</em
              >
            </div>

            <div
              class="input-style has-borders no-icon validate-field mb-4 input-style-always-active"
              :class="{ 'input-style-active': dataAccount.datebirth }"
            >
              <input
                type="date"
                max="2015-01-01"
                class="custom-form-control validate-text"
                id="form6a"
                v-model="dataAccount.datebirth"
                required
              />
              <label for="form6a">Tanggal Lahir</label>

              <em
                v-if="errors.datebirth && !dataAccount.datebirth"
                :class="{ 'color-red-dark font-800 me-4': errors.datebirth }"
                >(required)</em
              >
            </div>

            <!-- Domisili -->
            <div
              class="input-style has-borders no-icon validate-field mb-4 input-style-always-active"
              :class="{ 'input-style-active': dataProfile.country }"
            >
              <select
                class="form-control"
                id="form1k"
                v-model="dataProfile.country"
              >
                <option value="default" disabled="" selected="">Negara</option>
                <option
                  v-for="country in master_data.country"
                  :key="country.id"
                  :value="country.id"
                >
                  {{ country.name }}
                </option>
              </select>
              <label for="form1k">Negara</label>
              <span v-if="dataProfile.country"
                ><i class="fa fa-chevron-down"></i
              ></span>
              <i class="fa fa-check disabled valid color-green-dark"></i>
              <i class="fa fa-check disabled invalid color-red-dark"></i>
              <em
                v-if="!dataProfile.country"
                :class="{
                  'color-red-dark font-800 req-val': errors.country,
                }"
                >(required)</em
              >
            </div>

            <div
              class="input-style has-borders no-icon validate-field mb-4 input-style-always-active"
              id="form1lm"
              :class="{ 'input-style-active': province }"
            >
              <select class="form-control" id="form1k" v-model="province">
                <option value="default" disabled="" selected="">
                  Provinsi
                </option>
                <option
                  v-for="province in master_data.province"
                  :key="province.id"
                  :value="province.id"
                >
                  {{ province.name }}
                </option>
              </select>

              <label for="form1kl">Provinsi</label>
              <span v-if="province"><i class="fa fa-chevron-down"></i></span>
              <i class="fa fa-check disabled valid color-green-dark"></i>
              <i class="fa fa-check disabled invalid color-red-dark"></i>
              <em
                v-if="!province"
                :class="{
                  'color-red-dark font-800 req-val': errors.province,
                }"
                >(required)</em
              >
            </div>

            <div
              class="input-style has-borders no-icon validate-field mb-4 input-style-always-active"
              :class="{ 'input-style-active': dataProfile.city }"
            >
              <select
                class="form-control"
                id="form1k"
                v-model="dataProfile.city"
              >
                <option value="default" disabled selected>
                  Pilih Domisili
                </option>
                <option v-for="city in cities" :key="city.id" :value="city.id">
                  {{ city.name }}
                </option>
              </select>
              <label for="form1k">Kota Domisili</label>
              <span v-if="dataProfile.city"
                ><i class="fa fa-chevron-down"></i
              ></span>
              <i class="fa fa-check disabled valid color-green-dark"></i>
              <i class="fa fa-check disabled invalid color-red-dark"></i>
              <em
                v-if="!dataProfile.city"
                :class="{
                  'color-red-dark font-800 req-val': errors.city,
                }"
                >(required)</em
              >
            </div>
            <!-- End of Domisili -->

            <!-- lulusan_terakhir -->

            <p class="font-18 font-500 mb-3 mt-5 color-highlight">Pendidikan</p>
            <div
              class="input-style has-borders no-icon validate-field mb-4 input-style-always-active"
              :class="{ 'input-style-active': dataProfile.last_degree }"
            >
              <select
                class="custom-form-control"
                id="form1h"
                v-model="dataProfile.last_degree"
              >
                <option value="default" disabled="" selected="">
                  Pendidikan Terakhir
                </option>
                <option value="SD">SD</option>
                <option value="SMP">SMP</option>
                <option value="SMA">SMA</option>
                <option value="D3">D3</option>
                <option value="S1">S1</option>
                <option value="S1">S2</option>
                <option value="S3">S3</option>
              </select>
              <label for="form1h">Pendidikan Terakhir</label>
              <span v-if="dataProfile.last_degree"
                ><i class="fa fa-chevron-down"></i
              ></span>
              <i class="fa fa-check disabled valid color-green-dark"></i>
              <i class="fa fa-check disabled invalid color-red-dark"></i>
              <em
                v-if="!dataProfile.last_degree"
                :class="{
                  'color-red-dark font-800 req-val': errors.last_degree,
                }"
                >(required)</em
              >
            </div>

            <div
              class="input-style has-borders no-icon validate-field mb-4 input-style-always-active"
              :class="{ 'input-style-active': dataProfile.year_graduate }"
            >
              <select
                class="custom-form-control"
                id="form1h"
                v-model="dataProfile.year_graduate"
              >
                <option value="default" disabled="" selected="">
                  Tahun Lulus
                </option>
                <option value="1990">1990</option>
                <option value="1991">1991</option>
                <option value="1992">1992</option>
                <option value="1993">1993</option>
                <option value="1994">1994</option>
                <option value="1995">1995</option>
                <option value="1996">1996</option>
                <option value="1997">1997</option>
                <option value="1998">1998</option>
                <option value="1999">1999</option>
                <option value="2000">2000</option>
                <option value="2001">2001</option>
                <option value="2002">2002</option>
                <option value="2003">2003</option>
                <option value="2004">2004</option>
                <option value="2005">2005</option>
                <option value="2006">2006</option>
                <option value="2007">2007</option>
                <option value="2008">2008</option>
                <option value="2009">2009</option>
                <option value="2010">2010</option>
                <option value="2011">2011</option>
                <option value="2012">2012</option>
                <option value="2013">2013</option>
                <option value="2014">2014</option>
                <option value="2015">2015</option>
                <option value="2016">2016</option>
                <option value="2017">2017</option>
                <option value="2018">2018</option>
                <option value="2019">2019</option>
                <option value="2020">2020</option>
                <option value="2021">2021</option>
                <option value="2022">2022</option>
              </select>
              <label for="form1">Tahun Lulus</label>

              <span v-if="dataProfile.year_graduate"
                ><i class="fa fa-chevron-down"></i
              ></span>
              <i class="fa fa-check disabled valid color-green-dark"></i>
              <i class="fa fa-check disabled invalid color-red-dark"></i>
              <em
                v-if="!dataProfile.year_graduate"
                :class="{
                  'color-red-dark font-800 req-val': errors.year_graduate,
                }"
                >(required)</em
              >
            </div>
            <div
              class="input-style has-borders no-icon validate-field mb-4 input-style-always-active"
            >
              <select
                class="custom-form-control"
                id="form1h"
                v-model="freshgraduate"
              >
                <option value="yes">Iya</option>
                <option value="no">Tidak</option>
              </select>
              <label for="form1h"
                >Apakah kamu baru lulus & belum berpengalaman ?</label
              >
              <span v-if="freshgraduate"
                ><i class="fa fa-chevron-down"></i
              ></span>
              <i class="fa fa-check disabled valid color-green-dark"></i>
              <i class="fa fa-check disabled invalid color-red-dark"></i>
              <em
                v-if="!freshgraduate"
                :class="{
                  'color-red-dark font-800 req-val': errors.freshgraduate,
                }"
                >(required)</em
              >
            </div>
            <!-- End oflulusan_terakhir -->

            <!-- pekerjaan saat ini -->

            <div v-if="freshgraduate === 'no'">
              <p class="font-18 font-500 mb-3 mt-5 color-highlight">
                Pekerjaan Terakhir
              </p>
              <div
                class="input-style has-borders no-icon validate-field mb-4"
                :class="{ 'input-style-active': dataProfile.current_job_title }"
              >
                <input
                  type="email"
                  class="form-control validate-name"
                  id="form1g"
                  v-model="dataProfile.current_job_title"
                  placeholder="Nama Pekerjaan Saat ini"
                  required
                />
                <span v-if="dataProfile.current_job_title"
                  ><i class="fa fa-chevron-down"></i
                ></span>
                <i class="fa fa-check disabled valid color-green-dark"></i>
                <i class="fa fa-check disabled invalid color-red-dark"></i>
                <em
                  v-if="!dataProfile.current_job_title"
                  :class="{
                    'color-red-dark font-800 req-val': errors.current_job_title,
                  }"
                  >(required)</em
                >
              </div>

              <div
                class="input-style has-borders no-icon mb-4"
                :class="{ 'input-style-active': dataProfile.current_job_desc }"
              >
                <textarea
                  id="form1c"
                  v-model="dataProfile.current_job_desc"
                  placeholder="Deskripsi Pekerjaan Sekarang"
                  required
                ></textarea>
                <label for="form1c">Deskripsi Pekerjaan</label>
                <em
                  v-if="!dataProfile.current_job_desc"
                  :class="{
                    'color-red-dark font-800 req-val': errors.current_job_desc,
                  }"
                  >(required)</em
                >
              </div>
            </div>
            <!-- pekerjaan saat ini -->

            <!-- Submit Form Button -->
            <a
              @click.prevent="submitFormProfile"
              href="#"
              class="btn btn-full btn-m gradient-highlight rounded-s font-13 font-600 mt-4"
              >Simpan Perubahan</a
            >
          </div>
          <br />
          <br />
          <br />
          <br />
          <!--End Form -->
        </div>
      </div>
    </div>

    <!-- end of user profile -->

    <!-- applied modals -->
    <div
      id="applied"
      class="menu menu-box-modal rounded-m"
      data-menu-hide="1500"
      data-menu-width="250"
      data-menu-height="170"
    >
      <h1 class="text-center mt-3 pt-2">
        <i class="fa fa-check-circle scale-icon fa-3x color-green-dark"></i>
      </h1>
      <h3 class="text-center pt-2">Berhasil di Ajukan</h3>
    </div>
    <!--End applied modals -->

    <!-- check cv modals -->
    <div
      id="cv-validation"
      class="menu menu-box-modal bg-highlight rounded-m p-4"
      data-menu-height="310"
      data-menu-width="350"
      style="display: block; width: 350px"
    >
      <h5 class="boxed-text-l color-white">Apply Berhasil!</h5>
      <p class="boxed-text-l color-white opacity-70">
        Profil anda belum lengkap.
        <br />
        Upload CV anda untuk kesempatan diterima yang lebih besar.
      </p>
      <div class="d-flex flex-column gap-2">
        <router-link
          :to="{ name: 'UploadCV' }"
          class="close-menu btn btn-m btn-center-l button-s shadow-l rounded-s text-uppercase font-600 bg-white color-black"
          >Upload CV sekarang</router-link
        >
        <a
          @click="menuClose"
          href="#"
          class="close-menu btn btn-m btn-center-l button-s shadow-l rounded-s text-uppercase font-600 bg-white color-black"
          >Nanti saja</a
        >
      </div>
    </div>
    <!-- End check cv modals -->

    <!-- required Login -->
    <div
      id="required-login"
      class="menu menu-box-modal bg-highlight rounded-m"
      data-menu-height="310"
      data-menu-width="350"
      style="display: block; width: 350px; height: 310px"
    >
      <h1 class="text-center mt-4">
        <i
          class="fa fa-3x fa-times-circle scale-box color-white shadow-xl rounded-circle"
        ></i>
      </h1>
      <h1 class="text-center mt-3 text-uppercase color-white font-700">
        Wooops!
      </h1>
      <p class="boxed-text-l color-white opacity-70">
        Ayoo!! Daftar terlebih dahulu, dan ajukan lowongan yang dinginkan<br />
      </p>
      <router-link
        :to="{ name: 'SignIn', query: { to: '/job-seeker/job/' + jobData.id } }"
        class="close-menu btn btn-m btn-center-l button-s shadow-l rounded-s text-uppercase font-600 bg-white color-black"
        >Daftar</router-link
      >
    </div>
    <!-- required login -->
    <div
      id="emailVerifyNotif"
      class="menu menu-box-modal bg-highlight rounded-m"
      data-menu-height="310"
      data-menu-width="350"
      style="display: block; width: 350px; height: 310px"
    >
      <h1 class="text-center mt-4">
        <i
          class="fa fa-3x fa-info-circle scale-box color-white shadow-xl rounded-circle"
        ></i>
      </h1>

      <p class="boxed-text-l color-white opacity-70">
        Email anda belum terverifikasi<br />
        Silahkan cek inbox email Anda untuk melakukan verifikasi !
      </p>
      <a
        @click="menuClose"
        href="#"
        class="close-menu btn btn-m btn-center-l button-s shadow-l rounded-s text-uppercase font-600 bg-white color-black"
        >Keluar</a
      >
    </div>
    <div @click="menuClose" class="menu-hider"></div>
  </div>

  <div
    id="toast-bookmark"
    class="toast toast-tiny toast-top bg-highlight"
    data-bs-delay="1000"
    data-bs-autohide="true"
  >
    <i class="fa fa-info me-3"></i>disimpan
  </div>

  <div
    id="notif-input-data-profile"
    class="menu menu-box-modal bg-highlight rounded-m"
    data-menu-height="280"
    data-menu-width="350"
    style="display: block; width: 350px; height: 310px"
  >
    <h1 class="text-center mt-4">
      <i
        class="fa fa-3x fa-info-circle scale-box color-white shadow-xl rounded-circle"
      ></i>
    </h1>
    <p class="boxed-text-l color-white font-16 font-600 mb-0">
      Pilihan Bagus !
    </p>
    <p class="boxed-text-l color-white mb-3">
      Ayo lengkapi data berikut untuk apply lowongan ini
    </p>
    <a
      @click="showJobDetailForm()"
      class="close-menu btn btn-m btn-center-l button-s shadow-l rounded-s text-uppercase font-600 bg-white color-black"
      >Lanjutkan!</a
    >
  </div>
</template>

<script>
import axios from "axios";
import { init_template, menuClose, menuOpen } from "@/components/menu/index.js";

import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import { Toast } from "bootstrap";
import useClipboard from "vue-clipboard3";

export default {
  name: "JobDetail",
  props: {
    job: Object,
  },
  inject: ["getApply"],
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      showJobDetail: false,
      showBookmark: true,
      apiEndpoint: process.env.VUE_APP_ROOT_API_NODE,
      jobTypeMsg: "",
      bookmarked: false,
      disableBtn: false,
      btnText: "Apply",
      jobData: "",
      logoUrl: axios.defaults.baseURL,
      isLoading: true,
      userProfileId: "",
      errors: [],
      apiUrl: "/api/v1/job-seeker/job/",
      isAuthenticated: this.$store.state.isAuthenticated,
      isVerified: false,
      userType: this.$store.state.account.userType,
      applyJob: "",
      applyJobFormOpen: false,
      applyJobPayload: {
        job_id: 0,
        about: "",
        current_job_title: "",
        current_job_salary: "",
        current_job_desc: "",
        current_job_start: "",
        lulusan_terakhir: "",
        pref_salary_start: "",
        pref_salary_end: "",
      },
      inviteCode: this.$route.query.invite_code,
      //user profile
      freshgraduate: "yes",
      dataAccount: {
        gender: "",
        datebirth: "",
      },
      dataProfile: {
        country: "",
        city: "",
        last_degree: "",
        year_graduate: "",

        current_job_title: "",
        current_job_desc: "",
      },
      province: "",
      master_data: {},
      cities: [],
    };
    hideInformation: false;
  },
  watch: {
    province() {
      this.cities = this.master_data.province.filter((item) => {
        return item.id === this.province;
      });
      if (this.cities[0]) {
        this.cities = this.cities[0].city;
      }
    },

    categoryL1() {
      this.category.level2 = this.allCategory.filter((item) => {
        return item.parent === this.categoryL1;
      });
    },

    categoryL2() {
      this.category.level3 = this.allCategory.filter((item) => {
        return item.parent === this.categoryL2;
      });
    },
  },
  beforeMount() {
    this.getAccountInfo();
  },
  mounted() {
    console.log('htis.', this.userType)
    if(this.userType === 'E'){
      this.showBookmark = false
    }
    document.title = "pasjob";
    init_template();
    this.userProfileId = this.$store.state.userProfileId;
    var jobId = this.$route.params.id;
    this.master_data = this.$store.state.master_data;
    this.getData(jobId);
  },
  methods: {
    menuOpen,
    menuClose,
    async getAccountInfo() {
      await axios
        .get("/api/v1/auth/user/")
        .then((response) => {
          if (response.data) {
            console.log("response : ", response.data);
            this.isVerified = response.data.is_verified;
            console.log("is verify : ", this.isVerified);
          }
        })
        .catch((error) => {
          if (error.response) {
            for (const property in error.response.data) {
              this.errors.push(`${property}: ${error.response.data[property]}`);
            }

            console.log(
              "response data getApply : " + JSON.stringify(error.response)
            );
          } else if (error.message) {
            console.log("error :" + JSON.stringify(error));
          }
        });
    },

    async getBookmarStatus(jobId) {
      await axios
        .get("/api/v1/job-seeker/bookmark/?job_id=" + jobId)
        .then((response) => {
          if (response.data.marked === true) {
            this.bookmarked = true;
          }
        })
        .catch((error) => {
          if (error.response) {
            for (const property in error.response.data) {
              this.errors.push(`${property}: ${error.response.data[property]}`);
            }

            console.log(
              "response data getApply : " + JSON.stringify(error.response)
            );
          } else if (error.message) {
            console.log("error :" + JSON.stringify(error));
          }
        });
    },
    async bookmark() {
      await axios
        .post("/api/v1/job-seeker/bookmark/", { job: this.jobData.id })
        .then((response) => {
          if (response.data.message === "success") {
            this.bookmarked = response.data.marked;
            if (this.bookmarked) {
              var notificationToast = document.getElementById("toast-bookmark");
              notificationToast = new Toast(notificationToast);
              notificationToast.show();
            }
          }
        })
        .catch((error) => {
          if (error.response) {
            for (const property in error.response.data) {
              this.errors.push(`${property}: ${error.response.data[property]}`);
            }

            console.log(
              "response data getApply : " + JSON.stringify(error.response)
            );
          } else if (error.message) {
            console.log("error :" + JSON.stringify(error));
          }
        });
    },
    getDate(date) {
      var re = /\d+-\w+-\d+/g;
      var str = date;
      return str.match(re)[0];
    },
    imageUrlAlt(event) {
      event.target.src = "/images/defaults/default_logo.png";
    },

    checkApply(job_id) {
      //  get apply job  id
      const dataApply = this.$store.state.apply;
      if (dataApply) {
        const apply_job = dataApply.map((x) => x.job);
        if (apply_job.includes(job_id) && this.isAuthenticated) {
          this.disableBtn = true;
          this.btnText = "Already Applied";
        }
      }
    },
    showJobDetailForm() {
      this.showJobDetail = false;
      this.menuClose();
    },

    /*job detail*/
    async getData(id) {
      var url = this.apiUrl + id + "/";
      await axios
        .get(url)
        .then((response) => {
          console.log(response);
          this.jobData = response.data;
          this.logoUrl = response.data.company.get_logo;
          this.getBookmarStatus(response.data.id);

          if (this.jobData.job_type === "VC") {
            this.jobTypeMsg = "Lowongan";
          } else {
            this.jobTypeMsg = "Freelance";
          }
          if (this.userType === "J") {
            this.checkApply(this.jobData.id);
          }
          this.isLoading = false;
          this.showJobDetail = true;
        })
        .catch((error) => {
          if (error.response) {
            console.log(
              "response data getApply : " + JSON.stringify(error.response.data)
            );
          } else if (error.message) {
            console.log("error :" + JSON.stringify(error.message));
          }
        });
    },

    async apply(job_id) {
      if (!this.isAuthenticated) {
        console.log("not auth : ", this.isAuthenticated);
        this.menuOpen("required-login");
      } else if (!this.isVerified) {
        console.log("not verify");
        this.menuOpen("emailVerifyNotif");
      }
      const dataApplyJob = this.$store.state.apply;
      console.log('apply data : ', dataApplyJob)
      if (dataApplyJob) {
        const apply_job_id = dataApplyJob.map((x) => x.job);
        if (!apply_job_id.includes(job_id) && this.isAuthenticated) {
          /*apply job*/
          const apiUrl = "api/v1/job-seeker/job/apply/";

          const formData = {
            job: job_id,
          };

          await axios
            .post(apiUrl, formData)
            .then((response) => {
              console.log("response data ", response.data);
              var apply = this.$store.state.apply;
              apply.push(response.data);
              this.$store.commit("addApply", apply);
              this.disableBtn = true;
              this.btnText = "Already Applied";

              // if (response.data.is_upload_resume === false) {
              //   this.menuOpen("cv-validation");
              // } else {
              this.showJobDetail = true;
              setTimeout(() => {
                this.menuOpen("applied");
              }, 250);
              // }
            })
            .catch((error) => {
              if (error.response) {
                for (const property in error.response.data) {
                  this.errors.push(
                    `${property}: ${error.response.data[property]}`
                  );
                }
                if (error.response.data) {
                  if (error.response.data.detail === "user profile not valid") {
                    this.showJobDetail = false;
                    this.menuClose();
                    // this.menuOpen("notif-input-data-profile");
                  }
                }
              } else if (error.message) {
                console.log("error :" + JSON.stringify(error));
              }
            });
        }
      }
    },

    async submitFormProfile() {
      this.errors = {};
      const formData = {
        profile: this.dataProfile,
        account: this.dataAccount,
      };

      let reqInput = {
        ...this.dataProfile,
        ...this.dataAccount,
        province: this.province,
      };

      if (this.freshgraduate === "yes") {
        delete reqInput.current_job_desc;
        delete reqInput.current_job_title;
      }

      console.log("req input : ", reqInput);
      for (const key in reqInput) {
        if (reqInput[key] === "") {
          this.errors[key] = "Empty Input";
          this.errors["errorMsg"] = "Pastikan semua form sudah di isi";
        }
      }
      console.log("this errors : ", this.errors);

      if (!Object.keys(this.errors).length) {
        formData["profile"]["province"] = this.province;
        await axios
          .post("/api/v1/job-seeker/profile/user/update/", formData)
          .then((response) => {
            console.log("response : ", response);
            if (response.status === 200) {
              this.apply(this.jobData.id);
            }
          })
          .catch((error) => {
            if (error.response) {
              for (const property in error.response.data) {
                this.errors[
                  "error"
                ] = `${property}: ${error.response.data[property]}`;
              }
              console.log(JSON.stringify(error.response.data));
            } else if (error.message) {
              this.errors["error"] = "Something went wrong. please try again";
              console.log(JSON.stringify(error));
            }
          });
      }
    },
  },
};
</script>

<style>
.keep-spaces {
  white-space: pre-wrap;
}

.bluer-layer {
  position: relative;
  filter: blur(4px);
}

#text-information {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  background: rgba(208, 208, 208, 0.34);
  /*! filter: blur(5px); */
  padding: 20px;
  color: black;
  border-radius: 20px;
}
</style>
